<template>
    <b-list-group-item class="flex-column align-items-start">
        <div class="row align-items-center">
            <div class="col-auto">
                <!-- Avatar -->
                <a href="#!" class="avatar avatar-lg">
                    <router-link :to="linkTo"><img :src="image" class="avatar-img rounded" /></router-link>
                </a>
            </div>
            <div class="col ml-n2">
                <!-- Title -->
                <h4 class="mb-1 name">
                    <router-link :to="linkTo">{{ propertyName }}</router-link>
                </h4>

                <!-- Time -->
                <p class="card-text small text-muted">
                    {{ name }}
                </p>
            </div>          
        </div>
        <!-- / .row -->
    </b-list-group-item>
</template>
<script>
import {
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_MANAGER,
} from "@/acl/roles";
import {
    M_COMPANY_USER_DELETE,
    M_LOCATION_USER_DELETE,
    M_COMPANY_CHANGE_ROLE_FORM,
} from "@/components/modals/types";
import {
    MEMBER_GET,
} from "@/store/types/members";

export default {
    data() {
        return {
            propertyName: "",
            name: "",
            image: "",
            linkTo: null,
        };
    },
    name: "user-role-item",    
    props: ["role", "employee", "company"],
    created() {
        this.user = this.$store.dispatch(MEMBER_GET, {
            locationId: this.$route.params.locationId,
            userId: this.$route.params.userId,
        });
        switch (this.role.Name) {
            case ROLE_COMPANY_OWNER:
                this.image =
                    this.role.Company.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Company.Name;
                this.name = this.$t("permissions.role_owner");
                this.linkTo = {
                    name: "company",
                    params: {
                        companyId: this.role.Company.Id,
                    },
                };
                break;
            case ROLE_COMPANY_MANAGER:
                this.image =
                    this.role.Company.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Company.Name;
                this.name = this.$t("permissions.role_cm");
                this.linkTo = {
                    name: "company",
                    params: {
                        companyId: this.role.Company.Id,
                    },
                };
                break;
            case ROLE_LOCATION_MANAGER:
                this.image =
                    this.role.Location.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Location.Name;
                this.name = this.$t("permissions.role_lm");
                this.linkTo = {
                    name: "location",
                    params: {
                        locationId: this.role.Location.Id,
                        companyId: this.role.Location.CompanyId,
                    },
                };
                break;
            case ROLE_DEVICE_INSTALLER:
                this.image =
                    this.role.Location.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Location.Name;
                this.name = this.$t("permissions.role_installer");
                this.linkTo = {
                    name: "location",
                    params: {
                        locationId: this.role.Location.Id,
                        companyId: this.role.Location.CompanyId,
                    },
                };
                break;
            case ROLE_KEY_MANAGER:
                this.image =
                    this.role.Location.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Location.Name;
                this.name = this.$t("permissions.role_km");
                this.linkTo = {
                    name: "location",
                    params: {
                        locationId: this.role.Location.Id,
                        companyId: this.role.Location.CompanyId,
                    },
                };
                break;
        }
    },
    methods: {
        deleteUser() {
            if (this.name === this.$t("permissions.role_cm")) {
                this.showModal({ user: this.user }, M_COMPANY_USER_DELETE);
            } else {
                this.showModal({ user: this.user }, M_LOCATION_USER_DELETE);                
            }
        },
        changeRole(user) {
            this.showModal({ user: this.user }, M_COMPANY_CHANGE_ROLE_FORM);
        },
        getRoleObject(roleName) {
            return this.name;
        },
    },
};
</script>
