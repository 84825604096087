<template>
    <div class="row justify-content-center">
        <b-col cols="12">
            <div class="header">
                <div class="container-fluid">
                    <!-- Body -->
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    {{ $t("users.profile") }}
                                </h6>
                                <h1 class="header-title">
                                    {{ $t("permissions.title") }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-container fluid>
                <b-row>
                    <b-col cols="12">
                        <div class="tab-content">
                            <div class="card">
                                <div class="card-body b-border">
                                    <zs-list-transition
                                        :showLoader="showLoader"
                                    >
                                        <template v-slot:content>
                                            <user-role-item
                                                v-for="(role,
                                                index) in getUserRolesFiltered"
                                                :role="role"
                                                v-bind:key="index"
                                            />
                                        </template>
                                        <template v-slot:empty>
                                            <empty-list
                                                :title="$t('staff.no_roles')"
                                            />
                                        </template>
                                    </zs-list-transition>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-col>
    </div>
</template>

<script>
import ListTransition from "@/components/common/ListTransition";
import UserRoleItem from "@/components/user/UserRoleItem";
import EmptyList from "@/components/common/EmptyList";
import { mapGetters } from "vuex";

import {
    USER_ACCEPT_INVITE,
    USER_DECLINE_INVITE,
    USER_GET_ROLES,
} from "@/store/types/user";

import { ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY } from "@/acl/roles";

export default {
    data() {
        return {
            showLoader: true,
        };
    },
    computed: {
        ...mapGetters(["getUser", "getUserRoles"]),
        getUserRolesFiltered() {
            return this.getUserRoles.filter(
                (item) =>
                    ![ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY].includes(
                        item.Name
                    )
            );
        },
    },
    created() {
        this.$store
            .dispatch(USER_GET_ROLES)
            .then(() => (this.showLoader = false));
    },
    methods: {
        
    },
    components: {
        "zs-list-transition": ListTransition,
        UserRoleItem,
        EmptyList,
    },
};
</script>
